import React from 'react';

import ConfirmDeleteGraph from './components/graph';

function ConfirmDeletes() {
  return (
    <>
      <ConfirmDeleteGraph />
    </>
  );
}

export default ConfirmDeletes;
